import {random} from "gsap/gsap-core";

require("../sass/style.scss");
import $ from 'jquery';

window.$ = $;
window.jQuery = $;

import {gsap, ScrollTrigger} from "gsap/all";

const {ScrollSmoother} = require('../js/vendor/ScrollSmoother.min');
gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

import Plyr from 'plyr';


(function ($) {


    //Section sectionVideoText
    function sectionVideoText(section) {
        //duration opacity for bg video
        let opacityBgSpeed = 1;

        if ($(section).find('.arrow').length > 0) {
            gsap.fromTo($(section).find('.arrow svg'),
                {
                    y: 2,
                    scale: 0.8
                },
                {
                    scale: 1,
                    y: -13,
                    duration: 1,
                    repeat: -1,
                    ease: "sine.in",
                    yoyo: true
                });
        }

        let video = false;

        // video player settings
        if ($(section).find('video').length > 0) {
            video = true;

            var player = new Plyr($(section).find('.player'), {
                controls: false,
                volume: 0,
                keyboard: false,
                loop: {
                    active: false,
                },
                playsinline: false,
                captions: {
                    active: true,
                    language: 'en'
                },
                fullscreen: {
                    enabled: false,
                    fallback: false,
                }
            });
            player.volume = 0;

            player.on('playing', (event) => {
                gsap.to($(section).find('.replay'), 0.5, {opacity: 0})
            });

            player.on('ended', (event) => {
                gsap.to($(section).find('.replay'), 1, {opacity: 1})
            });
        }

        if ($(section).data('index') == 1) {
            gsap.set($(section), {opacity: 1});
        } else {
            gsap.set($(section), {opacity: 0});
        }

        // init section timeLine
        let sectionVideoText = gsap.timeline({
            scrollTrigger: {
                trigger: $(section),
                scrub: 1,
                pin: true,
                start: "top top",
                end: ($(section).find('.text').length > 0) ? $(section).find('.text').length * 300 + "% center" : "+=350% center",
                ease: "power2",
                onToggle: self => {
                    (video === true) ? (self.isActive == true) ? player.play() : player.pause() : '';
                },
                onUpdate: self => {
                    // console.log(self)
                },
                // markers: true,

            }
        });


        if ($(section).find('.animate-text-top').length > 0) {

            sectionVideoText.to($(section), {
                opacity: 1,
                duration: opacityBgSpeed
            }, 1);


            if ($(section).data('index') != 1) {
                sectionVideoText.fromTo($(section).find('.animate-text-top'), {autoAlpha: 0}, {
                    autoAlpha: 1,
                    ease: "power2",
                });
            }


            let sectionVideoTextItem = gsap.utils.toArray($(section).find('.text'));

            sectionVideoTextItem.forEach((text, i) => {

                if ($(section).data('index') == 1) {
                    if (i == 0) {
                        gsap.set($(text), {y: '0%'});
                        sectionVideoText.to($(text), {y: '-100%', duration: 3});

                    } else {
                        sectionVideoText.to($(text), {y: '0%', duration: 3, ease: "power4",});
                        sectionVideoText.to($(text), {y: '-100%', duration: 3}, '>');
                    }

                } else {
                    sectionVideoText.to($(text), {y: '0%', duration: 3, ease: "power4",});
                    sectionVideoText.to($(text), {y: '-100%', duration: 3}, '>');
                }

            });
            if ($(section).data('index') != 1) {
                sectionVideoText.to($(section).find('.animate-text-top'), {autoAlpha: 0, duration: 0.5});
            }
        } else {


            sectionVideoText.to($(section), {
                opacity: 1,
                duration: opacityBgSpeed
            }, 0.5);


            sectionVideoText.to($(section), {
                opacity: 0,
                duration: opacityBgSpeed
            }, '>+=70%');
            sectionVideoText.add('', '>2');

        }

        if ($(section).find('.arrow').length > 0) {
            sectionVideoText.to($(section).find('.arrow'), {
                opacity: 0,
            }, '>-50%');
        }

        if ($(section).find('.animate-text-top').length > 0) {
            sectionVideoText.to($(section), {
                opacity: 0,
                duration: opacityBgSpeed
            }, '-=170%');
        }
    }

    //Section end

    //Section sectionSimpleText
    function sectionSimpleText(section) {
        let heigthAnimation = ($(section).find('.step-by-step').length > 0) ? $(section).find('.step-by-step .text').length * 100 : 200;
        // init section timeLine
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: $(section),
                start: "center center",
                end: heigthAnimation + "% top",
                ease: "power2",
                scrub: true,
                pin: true,
                //markers: true,
            }
        });

        tl.fromTo($(section).find('.wrap-text'), {y: '80%', opacity: 0}, {y: '0%', opacity: 1});

        if ($(section).find('.step-by-step').length > 0) {
            $(section).find('.step-by-step .text').each(function () {
                tl.fromTo($(this), {y: '80%', opacity: 0}, {y: '0%', opacity: 1});
            })
            tl.add('', 2);
        }

    }

    //Section end
    // Section section-simple-text-type-2
    function sectionSimpleTextType2(section) {
        //set image margin (text has absolute position)
        gsap.set($(section).find('.image'), {margin: -Math.round($($(section).find('.text')[0]).outerHeight()) + ' 0 20px 0'});
        // init section timeLine
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: $(section),
                start: "center center",
                end: ($(section).find('.text').length > 0) ? $(section).find('.text').length * 300 + "% center" : "+=350% center",
                ease: "power2",
                scrub: true,
                pin: true,
                //markers: true,
            }
        });
        tl.fromTo($(section), {opacity: 0}, {opacity: 1});

        let sectionTextItem = gsap.utils.toArray($(section).find('.text'));
        let last = sectionTextItem.length;
        // add animation to timeLine
        sectionTextItem.forEach((text, i) => {
            if (i != 0) {
                tl.fromTo(text, {opacity: 0}, {opacity: 1, duration: 2});
            }
            if (last - 1 != i) {
                tl.to(text, {opacity: 0}, '>1');

            }
        })
        tl.to($(section), {opacity: 0});
    }

    //Section end

    //Section sectionLeftRightImage
    function sectionLeftRightImage(section) {
        // init section timeLine
        var sectionLeftRightImage = gsap.timeline({
            scrollTrigger: {
                trigger: $(section),
                start: "top top",
                end: $(section).find('.item').length * 450 + "% center",
                ease: "power2",
                scrub: true,
                pin: true,
                //   markers: true,
                //  onUpdate:()=>   console.log(tl),

            }
        });
        let sectionLeftRightImageItem = gsap.utils.toArray($(section).find('.item'));
        // create player control
        var players = Array.from($(section).find('.player')).map((p) => new Plyr(p, {
            controls: false,
            volume: 0,
            keyboard: false,
            playsinline: false,
            fullscreen: {
                enabled: false,
                fallback: false,
            },
            loop: {
                active: false,
            },
            captions: {
                active: true,
                language: 'en'
            },

        }));
        //double set volume 0 , because option line (volume:0) dont work
        players.map((p) => p.volume = 0);


        // add animation to timeLine
        sectionLeftRightImageItem.forEach((text, i) => {
            if ($(text).find('.player').length > 0) {
                var player = $(text).find('.player');
            }
            player.on('playing', (event) => {
                gsap.to($(text).find('.replay'), 0.5, {opacity: 0})
            });

            player.on('ended', (event) => {
                gsap.to($(text).find('.replay'), 1, {opacity: 1})
            });


            sectionLeftRightImage.to($(text).find('.image'), {
                opacity: 1,
                duration: 5,
                onStart: () => {
                    (player) ? player[0].play() : '';
                },
                onReverseComplete: () => {
                    (player) ? player[0].pause() : '';
                },
                zIndex: 2

            });

            sectionLeftRightImage.fromTo($(text).find('.text'), {
                y: '90%',
                opacity: 0
            }, {
                y: '0%',
                opacity: 1,
                ease: "power2",
                duration: 5
            }, '>-5.5');

            sectionLeftRightImage.to($(text).find('.text'), {
                y: '-70%',
                opacity: 0,
                ease: "power2",
                duration: 5
            }, '>+=30%');

            sectionLeftRightImage.to($(text).find('.image'), {
                opacity: 0,
                duration: 1,
                onReverseComplete: () => {
                    (player) ? player[0].play() : '';
                },
                onComplete: () => {
                    (player) ? player[0].pause() : '';
                },
                zIndex: 0
            }, '>-4');

        })
    }

    //Section end

    //Section sectionLeftRightImage
    function sectionGraphText(section) {
        let animationDuration = 400; // height of animation
        // init section timeLine
        var sectionGraphText = gsap.timeline({
            scrollTrigger: {
                trigger: $(section),
                start: "top top",
                end: animationDuration + "% center",
                ease: "power2",
                scrub: true,
                pin: true,
                // markers: true,
                //  onUpdate:()=>   console.log(tl),

            }
        });

        let sectionGraphTextItem = gsap.utils.toArray($(section).find('.item'));
        // add item to animation timeLine
        sectionGraphTextItem.forEach((text, i) => {

            if ($(text).find('.text').length > 0) {
                sectionGraphText.fromTo($(text).find('.text'), {
                    y: '50%',
                    opacity: 0
                }, {
                    y: '0%',
                    opacity: 1,
                    duration: 2
                });
            }

            if ($(text).find('.graph').length > 0) {

                $(text).find('.graph svg rect').each(function () {
                    sectionGraphText.to($(this), {opacity: 1});
                });

                sectionGraphText.fromTo($(text).find('.graph .title'), {
                    y: '0%',
                    opacity: 0
                }, {
                    y: '45%',
                    opacity: 1,
                    duration: 2
                }, "<-3");
            }
        });
    }

    //Section end

    // Section section-graph-text-2
    function sectionGrapt2(section) {
        // init section timeLine
        let animationDuration = 400; // height of animation

        let sectionGraph2 = gsap.timeline({
            scrollTrigger: {
                trigger: $(section),
                start: "top top",
                end: animationDuration + "% center",
                ease: "power2",
                scrub: true,
                pin: true,
                // markers: true,
                //  onUpdate:()=>   console.log(tl),
            }
        });

        sectionGraph2.fromTo($(section).find('.text-1'), {
            opacity: 0
        }, {
            opacity: 1,
        });

        sectionGraph2.fromTo($(section).find('svg'), {
            opacity: 0
        }, {
            opacity: 1,
        }, "<");

        sectionGraph2.to($(section).find('.graph-1'), {
            opacity: 1,
        }, "<");

        sectionGraph2.to($(section).find('.text-1'), {
            opacity: 0,
        });

        //svg
        sectionGraph2.to($(section).find('svg circle'), {
            x: 45,
        }, "<");

        sectionGraph2.to($(section).find('svg .st2019'), {
            opacity: 0.5,
        }, "<");

        sectionGraph2.to($(section).find('.graph-1'), {
            opacity: 0,
        }, "<");

        sectionGraph2.to($(section).find('svg .st2021'), {
            opacity: 1,
        }, "<");
        //end svg

        sectionGraph2.fromTo($(section).find('.text-2'), {
            opacity: 0
        }, {
            opacity: 1,
        }, "<");

        sectionGraph2.to($(section).find('.graph-2'), {
            opacity: 1,
        }, "<");

        sectionGraph2.add("", ">1");
    }

    // Smooth scroll (ALWAYS START BEFORE INIT ALL SECTIONS)
    let smoother = ScrollSmoother.create({
        smooth: 1,
        smoothTouch: 0.1,
        normalizeScroll: false,
        onUpdate: function (self) {
            (self.progress < 0.97 && self.progress > 0.04) ? gsap.to(".arrow-down-wrap-in", {autoAlpha: 1}) : gsap.to(".arrow-down-wrap-in", {autoAlpha: 0});
            $('.progress').text(Math.round(self.progress*100)+"%")
        }
    });
    //end Smooth scroll

    //create replay button
    $('video').each((e, s) => {
        let svg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000" height="800px" width="800px" version="1.1" id="Layer_1" viewBox="0 0 300.003 300.003" xml:space="preserve"><g><g><path d="M150.005,0C67.164,0,0.001,67.159,0.001,150c0,82.838,67.162,150.003,150.003,150.003S300.002,232.838,300.002,150    C300.002,67.159,232.844,0,150.005,0z M230.091,172.444c-9.921,37.083-43.801,64.477-83.969,64.477    c-47.93,0-86.923-38.99-86.923-86.923s38.99-86.92,86.923-86.92c21.906,0,41.931,8.157,57.228,21.579l-13.637,23.623    c-11-11.487-26.468-18.664-43.594-18.664c-33.294,0-60.38,27.088-60.38,60.38c0,33.294,27.085,60.38,60.38,60.38    c25.363,0,47.113-15.728,56.038-37.937h-20.765l36.168-62.636l36.166,62.641H230.091z"/></g></g></svg>`;
        let replay = `<div class="replay">` + svg + `</div>`;

        if ($(s).parent('section').find('.mute').length > 0) {
            if ($(s).parent('section').find('.caption').length > 0) {
                replay = `<div class="replay caption_space">` + svg + `</div>`;
                $(s).parent('section').find('.mute').after(replay);
            } else {
                $(s).parent('section').find('.mute').after(replay);
            }

        } else {
            $(s).parent('.video').find('.mute').after(replay);
        }

    });
    //end replay button

    //Start enumeration sections
    // get all sections
    const section = gsap.utils.toArray('section');
    //use a function based on a class
    section.forEach((section, i) => {
        if ($(section).hasClass('section-video-text')) {
            sectionVideoText(section);
        }
        if ($(section).hasClass('section-simple-text')) {
            sectionSimpleText(section);
        }
        if ($(section).hasClass('section-simple-text-type-2')) {
            sectionSimpleTextType2(section);
        }
        if ($(section).hasClass('section-left-right-image')) {
            sectionLeftRightImage(section);
        }
        if ($(section).hasClass('section-graph-text')) {
            sectionGraphText(section);
        }
        if ($(section).hasClass('section-graph-text-2')) {
            sectionGrapt2(section);
        }
    });
    //End enumeration

    //mute function to all video
    $('.mute').on("click", function () {
        if ($(this).hasClass('active')) {
            //set volume
            $('.player').each(function () {
                //if video has data-volume -> set data-value
                if ($(this).data('volume')) {
                    $(this)[0].volume = $(this).data('volume');
                } else {
                    $(this)[0].volume = 1;
                }

                $(this)[0].muted = false;
            })
        } else {
            $('.player').each(function () {
                $(this)[0].volume = 0;
                $(this)[0].muted = true;
            })
        }
        $('.mute').toggleClass('active');
    });
    //end mute function

    //cc function to all video
    $('.caption').on("click", function () {
        if ($(this).hasClass('active')) {
            //simple hide html content (plyr method do not work)
            $(this).parent().find('.plyr__captions').hide();
        } else {
            $(this).parent().find('.plyr__captions').show();
        }
        $(this).toggleClass('active');
    });
    //end cc function

    //replay button
    $('.replay').on("click", function (e) {
        let video;
        if ($(e.target).parents('section').hasClass('section-left-right-image')) {
            video = $(e.target).parents('.video').find('video');
        } else {
            video = $(e.target).parents('section').find('video');
        }
        $(video)[0].pause();
        $(video)[0].currentTime = 0;
        $(video)[0].play();
    });
    //end replay button

    //remove preloader after load
    $(window).on("load", function () {
        $('html, body').animate({scrollTop: 0}, 200);
        setTimeout(function () {
            $('#smooth-wrapper').css({
                overflow: 'auto'
            });
            $('.loader').remove();
            $('.caption').click();
        }, 500);
    });

    //replay button

    $('#to_top').click(function (e) {
        e.preventDefault();
        $('html, body').animate({scrollTop: 0}, 400);
        $('video').each((e, s) => {
            $(s)[0].pause();
            $(s)[0].currentTime = 0;
        })
    })

})(jQuery);